/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    a: "a",
    div: "div",
    p: "p"
  }, _provideComponents(), props.components), {ButtonCta} = _components;
  if (!ButtonCta) _missingMdxReference("ButtonCta", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "3-must-read-tips-for-new-hearing-aid-wearers",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#3-must-read-tips-for-new-hearing-aid-wearers",
    "aria-label": "3 must read tips for new hearing aid wearers permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "3 Must-Read Tips for New Hearing Aid Wearers"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Hearing aids are an important investment that will significantly improve your quality of life. However, many new wearers don’t experience all the amazing benefits because they don’t know how to make the best of them."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The good news is, this won’t happen to you. These 3 must-read tips for new hearing aid wearers will ensure your hearing aids stay in your ears and not in your dresser."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "1-wear-your-hearing-aids-in-quieter-places-first-like-at-home",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#1-wear-your-hearing-aids-in-quieter-places-first-like-at-home",
    "aria-label": "1 wear your hearing aids in quieter places first like at home permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "1. Wear your hearing aids in quieter places first, like at home"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Surprisingly, people with hearing loss will initially experience sensitivity to sounds. This can make hearing aids uncomfortable in the beginning, especially in louder settings. Imagine you were in a dark room and you turned on the lights. Your eyes would take some time to adjust. Your ears are no different. By easing yourself into more challenging hearing situations over time, you’ll be more successful. If you’ve been struggling with hearing loss and are in need of a solution, these three tips for new hearing aid wearers will ensure you experience all the benefits of today’s devices."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "2-new-hearing-aid-wearers-should-gradually-increase-wearing-time",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#2-new-hearing-aid-wearers-should-gradually-increase-wearing-time",
    "aria-label": "2 new hearing aid wearers should gradually increase wearing time permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "2. New hearing aid wearers should gradually increase wearing time"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Only wear your hearing aids for as long as they’re comfortable. Then, try to increase your wearing time every day. Acclimating to new hearing aids can take up to 30 days because your brain is rewiring the connections associated with all these new sounds. Early on you may also experience some itching or awareness of the hearing aids, which is normal and should eventually go away. By the end of your first week, aim to be wearing your hearing aids at least 6 hours a day."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "3-stay-committed-to-making-the-most-of-your-new-hearing-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#3-stay-committed-to-making-the-most-of-your-new-hearing-aids",
    "aria-label": "3 stay committed to making the most of your new hearing aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "3. Stay committed to making the most of your new hearing aids"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Experts say your hearing success is 30% due to the hearing aids and 70% due to your commitment to making them work for you. Similar to doing physical therapy to recover from an injury, it does take time and effort to regain your hearing with hearing aids. Since we know it can be uncomfortable in the beginning, your commitment to better hearing goes a long way. However, these three tips for new hearing aid wearers guarantee that your hearing aids stay in your ears and not in your drawer."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "follow-these-tips-if-youre-a-new-hearing-aid-weareror-previous-wearer",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#follow-these-tips-if-youre-a-new-hearing-aid-weareror-previous-wearer",
    "aria-label": "follow these tips if youre a new hearing aid weareror previous wearer permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Follow these tips if you’re a new hearing aid wearer…or previous wearer"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Our team of experienced consultants and licensed hearing care professionals can help you with all your hearing needs. In addition to these three tips, we provide you with personalized support and expert guidance as you get used to your new hearing aids. If you haven’t signed up for a 30-day trial, simply click the button below to check if you qualify."), "\n", React.createElement(ButtonCta, {
    copy: "Sign up for a 30-day trial",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
